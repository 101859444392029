import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { parseISO, addDays, differenceInDays } from "date-fns";
import { generalActions, alertActions, userActions } from "../../_actions";
import NotificationBox from "../../components/GeneralComponent/NotificationBox";
import PropertyReview from "../../components/PropertyComponent";
import "./styles.scss";
import { history, withRouter } from "../../_helpers";
import { NavBarComponent as DasboardNavBarComponent } from "../../components/DashboardComponent/common/NavBarComponent";
import {
  generalService,
  blockchainService,
  secureStorage,
} from "../../_services";
import LinearProgress from "@material-ui/core/LinearProgress";
import TokenPayModal from "../../components/DashboardComponent/modals/TokenPayModal";
import { arrayMoveImmutable } from "array-move";
import { initialState } from "./components/addPropertyPageParts/initialState";
import { setProperty } from "./components/addPropertyPageParts/utils";
import RenderDetailPart from "./components/addPropertyPageParts/renderDetailPart";
import RenderFinancialPart from "./components/addPropertyPageParts/renderFinancialPart";
import RenderPicturePart from "./components/addPropertyPageParts/renderPicturePart";
import RenderFileUploadPart from "./components/addPropertyPageParts/renderFileUploadPart";

class AddPropertyPage extends Component {
  constructor(props) {
    super(props);
    this.mapComponentRef = createRef();
    this.state = { ...initialState };
    this.ref = createRef();
  }

  async componentDidMount() {
    this.props.clearAlerts();
    this.props.getAllPartners();
    this.props.getPropertyPricing(this.state.modalAction);
    this.props.getFinancialInstruments();
    this.props.getDividendDistributions();
    this.props.getAvailableLocations();
    this.handleAddSectionsRow("Highlights", "the_highlights");

    const balanceObj = await blockchainService.getBalances();
    this.setState({
      user: {
        immoTokenBalance: balanceObj.immo,
        zbsTokenBalance: balanceObj.zbs,
      },
    });

    if (this.props.params?.id) {
      console.log("edit property");
      this.loadPropertyById(this.props.params?.id);
    } else {
      console.log("add property");
      const { property } = this.state;
      const { measurement } = this.props.users.profile_data;
      property.key_figures.forEach((keyFigure) => {
        if (
          keyFigure.property_key_en === "Sq Feet" &&
          measurement === "metric"
        ) {
          keyFigure.property_key_en = "Sq Meters";
        }
      });
      this.setState({
        loaded: true,
        property: { ...property },
      });
    }
  }

  async loadPropertyById(id) {
    try {
      const property = await generalService.getPropertyById(id);
      // prevent if logged in user wants to edit property they do not own
      // in that scenario allow only admin users
      const tokenData = userActions.parseJwt();
      if (
        window.location.pathname.includes("/edit/") &&
        !secureStorage.getItem("is_admin") &&
        tokenData.data.id !== property._user_id
      ) {
        console.log("prcenzi bato .!.");
        this.props.navigate("/marketplace");
      }
      const userMeasurement = this.props.users.profile_data.measurement;
      const prevState = this.state;
      let update = {};

      if (property && prevState.property.property_id !== property.property_id) {
        const propertyAllSet = setProperty(
          property,
          userMeasurement,
          this.onUpdatePropertyLocation
        );
        console.log("propertyAllSet: ", propertyAllSet);
        update = {
          ...update,
          property: propertyAllSet,
        };
      } else {
        update.property = prevState.property;
      }

      this.setState((prevState) => {
        return {
          ...prevState,
          ...update,
          property: update.property,
          loaded: true,
        };
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      all_partners,
      financial_instruments,
      dividend_distributions,
      available_locations,
      propertyPricing,
    } = nextProps.general;

    const prevState = this.state;

    var update = {};

    if (all_partners && all_partners.status === true) {
      update = {
        ...update,
        available_partners_list: all_partners.data,
      };
    }

    if (financial_instruments && financial_instruments.status === true) {
      update = {
        ...update,
        available_financial_instruments: financial_instruments.data,
      };
    }

    if (dividend_distributions && dividend_distributions.status === true) {
      update = {
        ...update,
        available_dividend_distributions: dividend_distributions.data,
      };
    }

    if (
      available_locations &&
      Object.keys(available_locations).length &&
      available_locations !== prevState.available_locations
    ) {
      update = {
        ...update,
        available_locations: available_locations,
      };
    }

    if (
      propertyPricing &&
      propertyPricing.pricing.amount !== this.state.propertyPricing.amount
    ) {
      update = {
        ...update,
        propertyPricing: { ...propertyPricing.pricing },
      };
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        ...update,
      };
    });
  }

  getSteps() {
    const map = {
      direct: [
        {
          name: "Property Details",
          render: () =>
            RenderDetailPart(
              this.state,
              this.handleChangePropertyType,
              this.onUpdateProperty,
              this.onUpdatePropertyLocation,
              this.mapComponentRef,
              this.handleAddPropertyLocation,
              this.handleAddKeyFigureRow,
              // this.handleKeyFiguresTabValue,
              this.handleKeyFigureChange,
              this.handleRemoveKeyFigureRow,
              // this.handleSectionsTabValue,
              this.handleSectionsChange,
              this.handleAddPartnerRow,
              this.handlePartnerChange,
              this.handleRemovePartnerRow,
              // this.handleLocationTabChange,
              this.handleEditorChange,
              this.props.navigate
            ),
        },
        {
          name: "Picture Upload",
          render: () =>
            RenderPicturePart(
              this.state,
              this.handleFloorPlanImageCapture,
              this.handleRemoveFloorPlanPicture,
              this.handlePropertyPictureCapture,
              this.onSortEnd,
              this.handlePropertyPictureTabValue,
              this.handlePropertyPictureDescChange,
              this.handleRemovePropertyPicture,
              this.ref
            ),
        },
        // {
        //   name: "Financial Details",
        //   render: () =>
        //     RenderSTOFactsPart(
        //       this.state,
        //       this.handleChange,
        //       this.calculateRemainingPromotionDays,
        //       this.handleStartDateChange
        //     ),
        // },
        {
          name: "Document Upload",
          render: () =>
            RenderFileUploadPart(
              this.state,
              this.handlePropertyDocCapture,
              this.handlePropertyDocTabValue,
              this.handlePropertyDocDescChange,
              this.handleRemovePropertyDoc
            ),
        },
        { name: "Review Page", render: this.renderReviewPart },
      ],
      default: [
        {
          name: "Property Details",
          render: () =>
            RenderDetailPart(
              this.state,
              this.handleChangePropertyType,
              this.onUpdateProperty,
              this.onUpdatePropertyLocation,
              this.mapComponentRef,
              this.handleAddPropertyLocation,
              this.handleAddKeyFigureRow,
              // this.handleKeyFiguresTabValue,
              this.handleKeyFigureChange,
              this.handleRemoveKeyFigureRow,
              // this.handleSectionsTabValue,
              this.handleSectionsChange,
              this.handleAddPartnerRow,
              this.handlePartnerChange,
              this.handleRemovePartnerRow,
              // this.handleLocationTabChange,
              this.handleEditorChange,
              this.props.navigate
            ),
        },
        {
          name: "Financial Details",
          render: () =>
            RenderFinancialPart(
              this.state,
              this.handleChange,
              this.handlePredictedValueTabChange
            ),
        },
        {
          name: "Picture Upload",
          render: () =>
            RenderPicturePart(
              this.state,
              this.handleFloorPlanImageCapture,
              this.handleRemoveFloorPlanPicture,
              this.handlePropertyPictureCapture,
              this.onSortEnd,
              this.handlePropertyPictureTabValue,
              this.handlePropertyPictureDescChange,
              this.handleRemovePropertyPicture,
              this.ref
            ),
        },
        // {
        //   name: "STO Facts",
        //   render: () =>
        //     RenderSTOFactsPart(
        //       this.state,
        //       this.handleChange,
        //       this.calculateRemainingPromotionDays,
        //       this.handleStartDateChange
        //     ),
        // },
        {
          name: "File Upload",
          render: () =>
            RenderFileUploadPart(
              this.state,
              this.handlePropertyDocCapture,
              this.handlePropertyDocTabValue,
              this.handlePropertyDocDescChange,
              this.handleRemovePropertyDoc
            ),
        },
        { name: "Review Page", render: this.renderReviewPart },
      ],
    };
    const steps = map[this.state.property.property_type] || map.default;
    return steps;
  }

  handleEditorValidation = () => {
    const { property } = this.state;
    var isAllSectionsFilled = property.sections.every(
      (section) => section.section_description_en !== ""
    );
    if (isAllSectionsFilled) {
      return true;
    } else {
      property.sections.map((section) => {
        var errorName = section.section_key + "_error";
        var errorMsg =
          section.section_heading_en + " description is required field";
        this.setState({
          [errorName]: section.section_description_en === "" ? errorMsg : "",
        });
        return null;
      });
      return false;
    }
  };

  handleNext = () => {
    this.form.isFormValid(false).then((isValid) => {
      const { activeStep } = this.state;
      var validAllEditorsVal =
        activeStep === 0 ? this.handleEditorValidation() : true;
      if (isValid && validAllEditorsVal) {
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
          the_highlights_error: "",
          the_property_error: "",
          the_investment_error: "",
        }));
      }
    });
  };

  handleBack = () => {
    const update = {
      activeStep: this.state.activeStep - 1,
    };
    if (this.state.activeStep === 2) {
      update["property"] = this.state.property;
      update["property"]["start_date"] = new Date();
      update["property"]["end_date"] = new Date();
    }
    this.setState((prevState) => ({
      ...update,
    }));
  };

  // handleLocationTabChange = (locationInputName) => (event, newValue) => {
  //   this.setState({
  //     [locationInputName]: newValue,
  //   });
  // };

  handlePredictedValueTabChange = (event, newValue) => {
    this.setState({
      predicted_value_increase_tabIndex: newValue,
    });
  };

  handleChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        [fieldName]: fieldValue,
      },
    }));
  };

  handleChangePropertyType = (event) => {
    const fieldValue = event.target.value;

    if (fieldValue === "direct") {
      this.setState((prevState) => {
        const sections = prevState.property.sections;
        sections.splice(2, 1);
        return {
          property: {
            ...prevState.property,
            property_type: fieldValue,
            sections,
          },
        };
      });
    } else {
      if (this.state.property.sections.length < 3) {
        this.handleAddSectionsRow("The Investment", "the_investment");
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            property_type: fieldValue,
          },
        }));
      }
    }
  };

  onUpdateProperty = (update) => {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        ...update,
      },
    }));
  };

  onUpdatePropertyLocation = (location_cords) => {
    this.mapComponentRef.current.setLocationMarkerOnChangePlaceFromParent(
      location_cords.position
    );
    this.handleAddPropertyLocation(location_cords);
  };

  handleStartDateChange = (date) => {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        start_date: date,
      },
    }));
  };

  handleEndDateChange = (date) => {
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        end_date: date,
      },
    }));
  };

  handleEditorChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        [fieldName]: fieldValue,
      },
    }));
  };

  // handleKeyFiguresTabValue = (index, tabFlag) => (event, newValue) => {
  //   const key_figures = [...this.state.property.key_figures];
  //   key_figures[index] = {
  //     ...key_figures[index],
  //     [tabFlag]: newValue,
  //   };
  //   this.setState((prevState) => ({
  //     property: {
  //       ...prevState.property,
  //       key_figures: key_figures,
  //     },
  //   }));
  // };

  handleAddKeyFigureRow = () => {
    const property = {
      property_key_en: "",
      property_value_en: "",
      tab_value_key: 0,
      tab_value_val: 0,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        key_figures: [...prevState.property.key_figures, property],
      },
    }));
  };

  handleRemoveKeyFigureRow = (index) => () => {
    const key_figures = [...this.state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      isDelete: 1,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        key_figures: key_figures,
      },
    }));
  };

  handleKeyFigureChange = (index) => (event) => {
    const { name, value } = event.target;
    const key_figures = [...this.state.property.key_figures];
    key_figures[index] = {
      ...key_figures[index],
      [name]: value,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        key_figures: key_figures,
      },
    }));
  };

  // handleSectionsTabValue = (index, tabFlag) => (event, newValue) => {
  //   const sections = [...this.state.property.sections];
  //   sections[index] = {
  //     ...sections[index],
  //     [tabFlag]: newValue,
  //   };
  //   this.setState((prevState) => ({
  //     property: {
  //       ...prevState.property,
  //       sections: sections,
  //     },
  //   }));
  // };

  handleAddSectionsRow = (heading = "", key = "") => {
    const section = {
      section_heading_en: heading,
      section_description_en: "",
      heading_tab_value: 0,
      description_tab_value: 0,
      section_key: key,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        sections: [...prevState.property.sections, section],
      },
    }));
  };

  handleRemoveSectionsRow = (index) => () => {
    const sections = [...this.state.property.sections];
    sections.splice(index, 1);
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        sections: sections,
      },
    }));
  };

  handleSectionsChange = (event) => {
    const sections = [...this.state.property.sections];
    const { name, value, index } = event.target;
    sections[index] = { ...sections[index], [name]: value };
    this.setState((prevState) => ({
      property: { ...prevState.property, sections: sections },
    }));
  };

  handleAddPartnerRow = () => {
    const partner = {
      partner_id: 0,
      involvement: "",
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        partners: [...prevState.property.partners, partner],
      },
    }));
  };

  handleRemovePartnerRow = (index) => () => {
    const partners = [...this.state.property.partners];
    partners[index] = {
      ...partners[index],
      isDelete: 1,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        partners: partners,
      },
    }));
  };

  handlePartnerChange = (index) => (event) => {
    const { name, value } = event.target;
    const partners = [...this.state.property.partners];
    partners[index] = {
      ...partners[index],
      [name]: value,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        partners: partners,
      },
    }));
  };

  handleAddPropertyLocation = (location_props) => {
    const { position, address } = location_props;
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        address_line1: address,
        property_location: position,
      },
    }));
  };

  handlePropertyPictureTabValue = (index) => (event, newValue) => {
    const property_picture = [...this.state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      tab_value: newValue,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_picture: property_picture,
      },
    }));
  };

  handleAddPropertyPicture = (preview, logo) => {
    const pictureObj = {
      property_picture_preview: preview,
      property_images: logo,
      image_description_en: "",
      tab_value: 0,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_picture: [...prevState.property.property_picture, pictureObj],
      },
    }));
  };

  handleRemovePropertyPicture = (picture) => () => {
    const property_picture = [...this.state.property.property_picture];
    const index = property_picture.findIndex((p) => p === picture);
    if (property_picture[index].id) {
      property_picture[index] = {
        ...property_picture[index],
        is_delete: 1,
      };
    } else {
      property_picture.splice(index, 1);
    }
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_picture: property_picture,
      },
    }));
  };

  handlePropertyPictureDescChange = (index) => (event) => {
    const { name, value } = event.target;
    const property_picture = [...this.state.property.property_picture];
    property_picture[index] = {
      ...property_picture[index],
      [name]: value,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_picture: property_picture,
      },
    }));
  };

  handlePropertyPictureCapture = (e) => {
    e.preventDefault();

    const files = Array.from(e.target.files);
    files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.handleAddPropertyPicture(reader.result, file);
      };
      reader.readAsDataURL(file);
    });
  };

  handlePropertyDocTabValue = (index) => (event, newValue) => {
    const property_documents = [...this.state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      tab_value: newValue,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_documents: property_documents,
      },
    }));
  };

  handlePropertyDocCapture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.handleAddPropertyDoc(file);
    };
    reader.readAsDataURL(file);
  };

  handleAddPropertyDoc = (file) => {
    const fileObj = {
      property_doc_file: file,
      property_doc_filename: file.name.toLowerCase(),
      file_description_en: "",
      tab_value: 0,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_documents: [...prevState.property.property_documents, fileObj],
      },
    }));
  };

  handlePropertyDocDescChange = (index) => (event) => {
    const { name, value } = event.target;
    const property_documents = [...this.state.property.property_documents];
    property_documents[index] = {
      ...property_documents[index],
      [name]: value,
    };
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_documents: property_documents,
      },
    }));
  };

  handleRemovePropertyDoc = (doc) => () => {
    const property_documents = [...this.state.property.property_documents];
    const index = property_documents.findIndex((d) => d === doc);
    if (property_documents[index].id) {
      property_documents[index] = {
        ...property_documents[index],
        is_delete: 1,
      };
    } else {
      property_documents.splice(index, 1);
    }
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_documents: property_documents,
      },
    }));
  };

  handleFloorPlanImageCapture = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState((prevState) => ({
          property: {
            ...prevState.property,
            property_floor_plans: [
              ...prevState.property.property_floor_plans,
              {
                floor_plan_image: file,
                floor_plan_image_preview: reader.result,
              },
            ],
          },
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  handleRemoveFloorPlanPicture = (floorPlan) => () => {
    const floor_plan_pictures = [...this.state.property.property_floor_plans];
    const index = floor_plan_pictures.findIndex((fp) => fp === floorPlan);
    if (floor_plan_pictures[index].id) {
      floor_plan_pictures[index] = {
        ...floor_plan_pictures[index],
        is_delete: 1,
      };
    } else {
      floor_plan_pictures.splice(index, 1);
    }
    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        property_floor_plans: floor_plan_pictures,
      },
    }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  closeUploadModal = () => {
    this.setState({
      uploadModalStatus: false,
      disabledButton: false,
    });
  };

  handlePropertyUpload = async () => {
    this.setState({ uploadModalStatus: false });
    const res = await blockchainService.spendTokens(
      this.state.propertyPricing.amount
    );
    if (res) {
      this.setState({
        property: {
          ...this.state.property,
          upload_transaction_id: res.id,
          is_draft: false,
        },
      });
      this.form.submit();
    }
  };

  submit = () => {
    if (this.state.property.property_id && !this.state.property.is_draft) {
      // update property part
      this.form.submit();
    } else {
      this.setState({
        disabledButton: true,
        uploadModalStatus: true,
      });
    }
  };

  handleSubmit = () => {
    const property = { ...this.state.property };
    delete property.macro_location_image_preview;
    delete property.micro_location_image_preview;

    const index = property.key_figures.findIndex((keyFigure) =>
      ["Sq Feet", "Sq Meters"].includes(keyFigure.property_key_en)
    );
    let val = property.key_figures[index].property_value_en;
    // if user switched to metric let user enter value and on save convert to feet
    if (this.props.users.profile_data.measurement === "metric") {
      val = `${Math.round(parseInt(val) / 0.092903)}`;
      property.key_figures[index].property_key_en = "Sq Feet";
    }
    property.key_figures[index].property_value_en = val;

    if (this.state.property.property_id) {
      // update property part
      delete property.upload_transaction_id;
      this.props.updateProperty(property, history).then((res) => {
        setTimeout(() => {
          this.props.clearAlerts();
          this.props.navigate("/dashboard/properties");
        }, 2000);
        // hash uploaded documents
        const db_property = res.property;
        this.props.hashDocuments(
          db_property.property_id,
          db_property.property_documents
        );
      });
    } else {
      this.props
        .addProperty(property)
        .then((db_property) => {
          setTimeout(() => {
            this.props.clearAlerts();
            this.setState({ disabledButton: false });
            this.props.navigate("/dashboard/properties");
          }, 2000);
          // hash uploaded proprety documents
          this.props.hashDocuments(
            db_property.property_id,
            db_property.property_documents
          );
        })
        .catch((error) => {
          setTimeout(() => this.props.navigate("/dashboard/properties"), 2000);
        });
    }
  };

  handleSaveDraft = () => {
    this.form.isFormValid(false).then((isValid) => {
      if (isValid) {
        this.setState({ disabledButton: true });
        // save property and redirect to edit page
        const property = { ...this.state.property };
        delete property.macro_location_image_preview;
        delete property.micro_location_image_preview;

        const index = property.key_figures.findIndex((keyFigure) =>
          ["Sq Feet", "Sq Meters"].includes(keyFigure.property_key_en)
        );
        let val = property.key_figures[index].property_value_en;
        // if user switched to metric let user enter value and on save convert to feet
        if (this.props.users.profile_data.measurement === "metric") {
          val = `${Math.round(parseInt(val) / 0.092903)}`;
          property.key_figures[index].property_key_en = "Sq Feet";
        }
        property.key_figures[index].property_value_en = val;
        if (this.state.property.property_id) {
          // update property part
          delete property.upload_transaction_id;
          this.props.updateProperty(property, history).then((res) => {
            setTimeout(() => {
              this.props.clearAlerts();
              this.setState({ disabledButton: false });
            }, 2000);
            // hash uploaded documents
            const db_property = res.property;
            this.props.hashDocuments(
              db_property.property_id,
              db_property.property_documents
            );
          });
        } else {
          this.props
            .addProperty(property)
            .then((db_property) => {
              setTimeout(() => {
                this.props.clearAlerts();
                this.setState({ disabledButton: false });
                window.location = `/dashboard/properties/edit/${db_property.property_id}`;
              }, 2000);
              // hash uploaded proprety documents
              this.props.hashDocuments(
                db_property.property_id,
                db_property.property_documents
              );
            })
            .catch((error) => {
              setTimeout(
                () => this.props.navigate("/dashboard/properties"),
                2000
              );
            });
        }
      } else {
        console.log("form not valid");
      }
    });
  };

  /**
   * @name updateSortImages
   * @description Update the sort order of the property pictures
   * @author Damir M.<dmozar@gmail.com>
   * @date 2024-06-15
   * @param {*} images
   */
  updateSortImages = async (images) => {
    const data = images.map((image, index) => {
      return {
        id: image.id,
        index: index,
      };
    });

    try {
      const res = await generalService.updatePropertySortPictures(data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @name onSortEnd
   * @description Sort the property pictures
   * @author Damir M.<dmozar@gmail.com>
   * @date 2024-06-15
   * @param {*} oldIndex
   * @param {*} newIndex
   */
  onSortEnd = async (oldIndex, newIndex) => {
    try {
      const array = this.state.property.property_picture || [];
      let newItems = arrayMoveImmutable(array, oldIndex, newIndex);
      newItems = newItems.map((item, index) => {
        item.index = index;
        return item;
      });
      this.setState((prevState) => ({
        property: {
          ...prevState.property,
          property_picture: newItems,
        },
      }));

      this.updateSortImages(newItems);
    } catch (error) {
      console.log(error);
    }
  };

  calculateRemainingPromotionDays = (startDate, durationDays = 30) => {
    const start = parseISO(startDate);
    const end = addDays(start, durationDays);
    const now = new Date();
    const remainingDays = differenceInDays(end, now);

    return remainingDays > 0 ? remainingDays : 0; // Ensure no negative days
  };

  renderReviewPart = () => {
    return (
      <div className="step-wizard-block block-6" id="step_6">
        <PropertyReview property={this.state.property} />
      </div>
    );
  };

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { alert } = this.props;

    return (
      <div className="main-content add-property-page">
        <DasboardNavBarComponent pageName="manage-property" />
        <Card>
          <CardContent>
            {alert.message && (
              <NotificationBox
                open={true}
                variant={alert.type}
                message={alert.message}
              />
            )}
            <Stepper activeStep={activeStep}>
              {steps.map(({ name }) => {
                return (
                  <Step key={name}>
                    <StepLabel>{name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <ValidatorForm
              className="default-form property-form add-property-form"
              onSubmit={this.handleSubmit}
              ref={(r) => {
                this.form = r;
              }}
              autoComplete="off"
              instantValidate
            >
              {steps[activeStep] &&
                steps[activeStep].render &&
                steps[activeStep].render()}
              <Container>
                {this.state.disabledButton && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Uploading property{" "}
                      <strong>{this.state.property.title}</strong>
                    </Typography>
                    <LinearProgress />
                    <br />
                    <br />
                  </>
                )}
              </Container>
              <div className="action-buttons">
                <Button
                  disabled={activeStep === 0 || this.state.disabledButton}
                  onClick={this.handleBack}
                >
                  Back
                </Button>
                {this.state.property.is_draft ? (
                  <Button
                    variant="contained"
                    className="draft-button"
                    onClick={this.handleSaveDraft}
                    disabled={this.state.disabledButton}
                  >
                    Save Draft
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    this.state.disabledButton ||
                    !this.state.property.start_date ||
                    !this.state.property.end_date
                  }
                  onClick={
                    activeStep < steps.length - 1
                      ? this.handleNext
                      : this.submit
                  }
                >
                  {activeStep === steps.length - 1
                    ? "Accept and Start Campaign"
                    : "Next"}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
        <TokenPayModal
          handleClose={this.closeUploadModal}
          open={this.state.uploadModalStatus}
          action={this.state.modalAction}
          propertyTitle={this.state.property.title}
          content={`You are about to ${this.state.modalAction} this property`}
          immoTokenBalance={this.state.user.immoTokenBalance}
          zbsTokenBalance={this.state.user.zbsTokenBalance}
          payAmount={this.state.propertyPricing.amount}
          isOpen={this.state.uploadModalStatus}
          handleFinish={this.handlePropertyUpload}
        />
        {this.state.uploadImages.length > 0 ? (
          <div className="upload-mask">
            <div className="upload-image-container">
              <h3>Upload in progress...</h3>
              <CircularProgress color="secondary" size={24} />
              <div className="upload-images">
                {this.state.uploadImages.map((image, index) => {
                  let innerClass = "upload-item next-upload";
                  if (index === this.state.currentUpload) {
                    innerClass = "upload-item current-upload";
                  }
                  if (index < this.state.currentUpload) {
                    innerClass = "upload-item prev-upload";
                  }
                  return (
                    <img
                      src={image.raw}
                      alt="upload"
                      className={innerClass}
                      key={index}
                    />
                  );
                })}
              </div>
              <div>
                <small>
                  {this.state.currentUpload} / {this.state.uploadImages.length}
                </small>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapState(state) {
  const { alert, general, location, users } = state;
  return { alert, general, location, users };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  addProperty: generalActions.addProperty,
  updateProperty: generalActions.updateProperty,
  getAllPartners: generalActions.getAllPartners,
  getPropertyPricing: generalActions.getPropertyPricing,
  getFinancialInstruments: generalActions.getFinancialInstruments,
  getDividendDistributions: generalActions.getDividendDistributions,
  getAvailableLocations: generalActions.getAvailableLocations,
  getPropertyById: generalActions.getPropertyById,
  hashDocuments: generalActions.hashDocuments,
};

const connectedPage = withRouter(
  connect(mapState, actionCreators)(AddPropertyPage)
);
export { connectedPage as AddPropertyPage };
